<template>
  <div id="divRegulationDetailsWrapper" class="rd-wrapper">
    <div v-html="content"></div>
  </div>
</template>
<script>
import StaticContent from '@/services/Api/staticContents';
export default {
  name: 'RegulationDetails',
  data() {
    return { content: '' };
  },
  created() {
    StaticContent.getStaticContent('RegulasyonDetaylari').then(res => {
      const {
        Data: { content },
      } = res.data;
      this.content = content;
    });
  },
};
</script>
<style scoped lang="scss">
.rd-wrapper {
  text-align: center;
  padding: 10px;
  white-space: pre-line;
}
</style>
